// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #1C1D2E; /* Couleur de fond de la page */
  color: #fff; /* Couleur du texte par défaut */
}

.App {
  display: flex;
  height: 100vh;
}

.card {
  background-color: #2E2F45; /* Couleur des cartes */
  border: none; /* Supprimer les bordures si nécessaire */
}

.MuiCardContent-root {
  padding: 16px;
}

h4, h6 {
  color: #fff; /* Couleur du texte */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,iCAAiC;EACjC,yBAAyB,EAAE,+BAA+B;EAC1D,WAAW,EAAE,gCAAgC;AAC/C;;AAEA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,yBAAyB,EAAE,uBAAuB;EAClD,YAAY,EAAE,yCAAyC;AACzD;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW,EAAE,qBAAqB;AACpC","sourcesContent":["body {\n  margin: 0;\n  font-family: 'Roboto', sans-serif;\n  background-color: #1C1D2E; /* Couleur de fond de la page */\n  color: #fff; /* Couleur du texte par défaut */\n}\n\n.App {\n  display: flex;\n  height: 100vh;\n}\n\n.card {\n  background-color: #2E2F45; /* Couleur des cartes */\n  border: none; /* Supprimer les bordures si nécessaire */\n}\n\n.MuiCardContent-root {\n  padding: 16px;\n}\n\nh4, h6 {\n  color: #fff; /* Couleur du texte */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
